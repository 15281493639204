<template>
  <!-- Link de Pago Vista previa-->
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3">
        <b-card body class="text-center card-shadow">
          <b-img
            fluid
            src="../../../assets/images/celcomLogo/logo.png"
            alt="Logo Celcom"
            style="height: 30px"
          />
        </b-card>
      </div>
    </div>

    <div v-if="!linkEliminado">
      <div v-if="validateDate">
        <div class="row" v-if="linkCargado">
          <!-- Nombre y avatar -->
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-12 ">
                <b-card body class="text-center card-shadow">
                  <div class="row">
                    <div class="col-lg-2 col-sm-12">
                      <b-avatar
                        size="70px"
                        :src="imagen"
                        variant="light"
                        class="avatarClass"
                      />
                    </div>
                    <div class="col-lg-10 col-sm-12">
                      <h4 class="font-weight-bolder text-card-detail">
                        {{ nombreProducto }}
                      </h4>
                      <p class="textPromocional text-card-detail">
                        <strong>{{ textoPromocional }}</strong>
                      </p>
                      <span id="descripcion" class="text-card-detail">{{ descripcionProducto }}</span>
                    </div>
                  </div>
                </b-card>
              </div>
              <div class="col-12">
                <b-card body class="card-shadow" v-if="descuento.length <= 0 && dataLinkActivo.enableDiscount == 1">
                  <b-form-checkbox
                    v-model="codigoEnabled"
                    class="custom-control-info flex-justify-content-center"
                  >
                    <label class="label-codigos text-card-detail" 
                      >Tengo un codigo de descuento</label
                    >
                  </b-form-checkbox>
                  <div class="row align-items-end" v-if="codigoEnabled">
                  <h4 class="mb-1 mt-2 ml-1">Código de descuento</h4>
                    <div class="col-lg-12 col-sm-12">
                      <b-form-input
                        class="col-lg-6 col-sm-12"
                        id="codigo"
                        v-model="codigoDesc"
                        type="url"
                        placeholder="Ingrese su código"
                      />
                      <span class="error-codigoDescuento mt-3" v-if="showErrorDescuento"> El código no existe, intente con otro.</span>
                    </div>
                    <div class="col">
                      <b-button
                        id="button-validar"
                        class="codigo-button col-lg-6 mt-2"
                        variant="primary"
                        @click="aplicaDescuento"
                        :disabled="codigoDesc.length < 2"
                      >
                        Validar
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </div>
              <div class="col-12">
                <div id="card-monto" class="card-shadow">
                  <h4 class="mb-3 mt-1">Resumen de la compra</h4>

                  <hr />
                  <div class="row mb-1">
                    <div class="col-6" v-if="this.dataLinkActivo.type == 2">
                      <h6 class="mt-1">Período de cobro</h6>
                    </div>
                    <div class="col-6" v-if="this.dataLinkActivo.type == 2">
                      <h6 class="mt-1 text-right">
                        {{ this.textPeriodicidad }}
                      </h6>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <h6 class="mt-1">Subtotal</h6>
                    </div>
                    <div class="col-6">
                      <h6 class="mt-1 text-right">
                        {{ subtotal }} {{ dataLinkActivo.currencyString }}
                      </h6>
                    </div>
                  </div>
                  <div class="row mb-1" v-if="descuento != ''">
                    <div class="col-6">
                      <h6 class="mt-1">Descuento</h6>
                    </div>
                    <div class="col-6">
                      <h6 class="mt-1 text-right">
                        - {{ descuento }} {{ dataLinkActivo.currencyString }}
                      </h6>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-6">
                      <h4 class="mt-1 total-amount">Total</h4>
                    </div>
                    <div
                      class="col-6 text-right"
                      v-if="this.dataLinkActivo.type == 2"
                    >
                      <h5 class="mt-1 text-right total-amount">
                        <b class="text-right"
                          >{{ total }} {{ dataLinkActivo.currencyString }} /
                          {{ this.textPeriodicidad }}</b
                        >
                      </h5>
                    </div>
                    <div
                      class="col-6 text-right"
                      v-if="this.dataLinkActivo.type == 1"
                    >
                      <h5 class="mt-1 text-right total-amount">
                        {{ total }} {{  dataLinkActivo.currencyString }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-card body class="text-center card-shadow">
                  <div class="row">
                    <div class="col-12">
                      <b-img
                        fluid
                        src="../../../assets/images/logo/Stripe_Logo.svg"
                        alt="Logo Stripe"
                        style="max-height: 30px"
                      />
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>

          <!-- Campos formulario -->
          <div class="col-lg-6 col-sm-12">
            <b-card body class="card-shadow">
              <h4 class="pt-1 col-6"><b>Datos del Cliente</b></h4>
              <span class="size-span-selecciona-medio">
                Todos los campos son obligatorios
              </span>
              <div>
                <div class="row">
                  <div
                    v-for="(listItem, index) in list2"
                    :class="listItem.column_size"
                    :key="index"
                  >
                    <label>{{ listItem.name ? listItem.name : '' }}</label>
                    <!-- v-id="index + '-Link'" -->
                    <b-form-input
                      class="mb-1"
                      v-on:keyup="copiarEmailInput"
                      v-model="listItem.model"
                      :state="statesDynamicForm[index]"
                    />
                    <label class="size-span-selecciona-medio" v-if="listItem.name=='Teléfono'||listItem.name=='Telefono'||listItem.name=='TELEFONO'">Ejemplo: +542641234567</label>
                    <label class="size-span-selecciona-medio" v-if="listItem.name=='RUT'">Ejemplo: 12345678-0</label>
                    <label class="size-span-selecciona-medio" v-if="listItem.name=='RUT de Empresa'">Ejemplo: 12345678-0</label>
                  </div>
                </div>
              </div>

              <hr />

              <h4 class="pt-1 col-6"><b>Forma de pago</b></h4>
              <span class="size-span-selecciona-medio"
                >Selecciona una forma de pago</span
              >
              <b-form-group>
                <v-select
                  v-model="formaElegida"
                  :dir="dir"
                  label="text"
                  :options="formaSelector"
                  :reduce="(Item) => Item.value"
                  class="select-size-lg mt-1"
                  @input="pasarelasSel()"
                  v-on:input="hideInputFormaPago()"
                  v-on:close="showInputFormaPago()"
                />
                <label
                  v-if="formaCheck"
                  class="font-italic"
                  style="color: red !important"
                  >Debe seleccionar una Forma de pago</label
                >
              </b-form-group>
              <hr class="mt-2" />
              <span class="size-span-selecciona-medio"
                >Selecciona una pasarela de pago</span
              >
              <b-form-group>
                <!-- <v-select v-model="medioElegido" :options="pasarelasSelector" :reduce="item => item.value" label="text" /> -->
                <v-select
                  v-model="medioElegido"
                  :dir="dir"
                  label="text"
                  :reduce="(Item) => Item.value"
                  :options="pasarelasSelector"
                  class="select-size-lg mt-1"
                  @input="verificaCambioPasarela()"
                  v-on:input="hideInputFormaPago()"
                  v-on:close="showInputFormaPago()"
                />
                <label
                  v-if="pasarelaCheck"
                  class="font-italic"
                  style="color: red !important"
                  >Debe seleccionar una Pasarela de pago</label
                >
                <!-- <v-select
                v-model="medioElegido"
                :dir="dir"
                :value="value"
                label="text"
                :options="pasarelasSelector"
                class="select-size-lg mt-1"
                v-on:input="hideInputMedioPago()"
                v-on:close="showInputMedioPago()"
              /> -->
                <!-- label="title" -->
              </b-form-group>

              <hr class="mt-2" />

              <div>
                <b-alert
                  v-height-fade.appear
                  :show="dismissCountDown"
                  dismissible
                  class="mb-1"
                  variant="warning"
                  id="empty-field-popup"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  <div class="alert-body">
                    <span class="bold">Advertencia! </span>
                    <br />
                    <span>Debes completar los campos obligatorios </span>
                  </div>
                </b-alert>
              </div>

              <!-- <div v-if="inputsMedioPagoVisible && formaElegida != 3"> -->
              <div
                v-if="
                  aceptoTerminosCondiciones &&
                    formaElegida != 3 &&
                    formaElegida != 4
                "
              >
                <!-- <h5 class="mt-2" v-if="medioElegido == 1 && formaElegida != 2">
                  {{ medioElegido == 1 ? 'Kushki' : 'Stripe' }}
                </h5>
                <h5 class="mt-2" v-if="medioElegido == 2 && formaElegida != 2">
                  {{ medioElegido == 2 ? 'Stripe' : 'Kushki' }}
                </h5> -->
              
                  
                    <div class="row credit-card-data card-shadow" 
                    v-if="medioElegido == 2 && formaElegida != 2"
>
                      <div class="row text-center"> 
                        <h4 class="ml-2 pt-1 col-12 text-card-detail"><b>Datos de la tarjeta</b></h4>

                      </div>
                      <div class="col-12">
                        <label><b>Nombre en la tarjeta</b></label>
                        <b-form-input
                          placeholder="Nombre de titular"
                          class="mb-1"
                          v-model="nombreTitular"
                          id="input-nombreTitular"
                          @input="cambia()"
                          type="text"
                        />
                      </div>
                      <div class="col-12">
                        <label><b>Número de Tarjeta</b></label>
                        <b-form-input
                        placeholder="Numero de la tarjeta"
                          class="mb-1 control"
                          id="card_number"
                          type="number"
                          @input="cambia()"
                          v-mask="'################'"
                          v-model="numeroTarjeta"
                        />
                      </div>
                      <div class="col-6">
                        <label><b>Fecha de expiración</b></label>
                        <b-form-input
                          placeholder="mm/YY"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="fechaExpiracion"
                          v-mask="'##/##'"
                        />
                      </div>
                      <div class="col-6">
                        <label><b>CVV</b></label>
                        <b-form-input
                        placeholder="cvv"
                          class="mb-1 control"
                          id="cc-csc"
                          type="password"
                          @input="cambia()"
                          v-model="cvv"
                          v-mask="'####'"
                        />
                      </div>
                    </div>

                    <div class="col-12 mt-2" v-if="medioElegido == 2">
                      <h4 class="pt-1"><b>Datos de Facturacion</b></h4>
                      <label>Email</label>
                      <b-form-input
                        class="mb-1 control"
                        id="card_email"
                        placeholder="Email"
                        type="email"
                        @input="cambia()"
                        required
                        v-model="emailCreditCard"
                      />
                    </div>

            
                 
                  <div v-if="medioElegido == 1 && formaElegida != 2">
                    <div class="row credit-card-data card-shadow">
                      <div class="row">
                        <h4 class="ml-2 pt-1 col-sm-12 text-card-detail"><b>Datos de la tarjeta</b></h4>
                        
                      </div>
                      <div class="col-12">
                        <label><b>Nombre en la tarjeta</b></label>
                        <b-form-input
                          placeholder="Nombre de titular"
                          class="mb-1"
                          v-model="nombreTitular"
                          id="input-nombreTitular"
                          @input="cambia()"
                          type="text"
                        />
                      </div>
                      <div class="col-12">
                        <label><b>Número de Tarjeta</b></label>
                        <b-form-input
                        placeholder="Numero de la tarjeta"
                          class="mb-1 control"
                          id="card_number"
                          type="number"
                          @input="cambia()"
                          v-mask="'################'"
                          v-model="numeroTarjeta"
                        />
                      </div>
                      <div class="col-6">
                        <label><b>Fecha de expiración</b></label>
                        <b-form-input
                          placeholder="mm/YY"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="fechaExpiracion"
                          v-mask="'##/##'"
                        />
                      </div>
                      <div class="col-6">
                        <label><b>CVV</b></label>
                        <b-form-input
                        placeholder="cvv"
                          class="mb-1 control"
                          id="cc-csc"
                          type="password"
                          @input="cambia()"
                          v-model="cvv"
                          v-mask="'####'"
                        />
                      </div>
                    </div>

                    <div class=" mt-1" v-if="formaElegida == 1">
                      <h4 class="pt-1 col-6"><b>Datos de Facturacion</b></h4>
                    </div>

                    <div class="row" v-if="formaElegida == 1">
                      <div class="col-12">
                        <label>Email</label>
                        <b-form-input
                          class="mb-1 control"
                          id="card_email"
                          placeholder="Email"
                          type="email"
                          @input="cambia()"
                          required
                          v-model="emailCreditCard"
                        />
                      </div>

                      <div class="col-12">
                        <label>Nombre</label>
                        <b-form-input
                          placeholder="Nombre"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="nombreCreditCard"
                        />
                      </div>
                      <div class="col-12">
                        <label>Apellido</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Apellido"
                          type="text"
                          @input="cambia()"
                          v-model="apellidoCreditCard"
                        />
                      </div>

                      <div class="col-12">
                        <label>Telefono</label>
                        <b-form-input
                          placeholder="Telefono"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="number"
                          min="0"
                          max="9"
                          v-model="telefonoCreditCard"
                        />
                      </div>
                      <div class="col-12">
                        <label>Direccion</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Direccion"
                          type="text"
                          @input="cambia()"
                          v-model="direccionCreditCard"
                        />
                      </div>

                      <div class="col-6">
                        <label>Ciudad</label>
                        <b-form-input
                          placeholder="Ciudad"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="ciudadCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <label>Pais</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Pais"
                          type="text"
                          @input="cambia()"
                          v-model="paisCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <label>Region</label>
                        <b-form-input
                          class="mb-1 control"
                          placeholder="Region"
                          id="cc-csc"
                          type="text"
                          @input="cambia()"
                          v-model="regionCreditCard"
                        />
                      </div>

                      <div class="col-6">
                        <label>Cod. Postal</label>
                        <b-form-input
                          placeholder="Cod Postal"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="codigoPostalCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <b-form-group>
                          <label>Tipo de Documento</label>
                          <v-select
                            v-model="tipoDocumentoCard"
                            :dir="dir"
                            label="text"
                            :options="documentOptions"
                            :reduce="(Item) => Item.value"
                            @input="elegirDocumento(tipoDocumentoCard)"
                            v-on:input="hideInputFormaPago()"
                            v-on:close="showInputFormaPago()"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <label>N° de Doc.</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          type="text"
                          min="0"
                          max="10"
                          @input="cambia()"
                          placeholder="Numero de doc."
                          v-model="documentoCard"
                        />
                        <label class="size-span-selecciona-medio" v-if="tipoDocumentoCard=='RUT'">Ejemplo: 12345678-0</label>
                      </div>
                    </div>
                  </div>
             
              </div>

              <div v-if="formaElegida == 4 && medioElegido == 1">
                <h5 class="mt-2">Datos para el pago</h5>
              </div>
              <div v-if="formaElegida == 4 && medioElegido == 1">
                <div class="row">
                  <div class="col-12">
                    <label>Email</label>
                    <b-form-input
                      class="mb-1"
                      type="email"
                      required
                      v-model="emailTransferenciaKushki"
                      @input="cambia()"
                      id="input-nombreTitular"
                    />
                    <b-form-group>
                      <label>Tipo de Documento</label>
                      <v-select
                        v-model="documentTypeTransfer"
                        :dir="dir"
                        label="text"
                        :options="documentOptions"
                        :reduce="(Item) => Item.value"
                        class="mb-1"
                        @input="elegirDocumento(documentTypeTransfer)"
                        v-on:input="hideInputFormaPago()"
                        v-on:close="showInputFormaPago()"
                      />
                    </b-form-group>
                    <label>N° de Doc.</label>
                    <b-form-input
                      class="mb-1"
                      type="text"
                      min="0"
                      max="9"
                      required
                      v-model="documentoTransfer"
                      @input="cambia()"
                      id="document-number"
                    />
                  </div>
                </div>
              </div>

              <div v-if="formaElegida == 3 && medioElegido == 1">
                <h5 class="mt-2">Datos para el pago</h5>
              </div>
              <div v-if="formaElegida == 3 && medioElegido == 1">
                <div class="row">
                  <div class="col-6">
                    <label>Nombre</label>
                    <b-form-input
                      class="mb-1"
                      v-model="nombreEfectivoKushki"
                      @input="cambia()"
                      type="text"
                      id="input-nombreTitular"
                    />
                  </div>
                  <div class="col-6">
                    <label>Apellido</label>
                    <b-form-input
                      class="mb-1"
                      v-model="apellidoEfectivoKushki"
                      @input="cambia()"
                      type="text"
                      id="input-nombreTitular"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>N° de identificacion</label>
                    <b-form-input
                      class="mb-1"
                      v-model="identificacionEfectivoKushki"
                      @input="cambia()"
                      type="number"
                      id="input-nombreTitular"
                    />
                  </div>
                  <div class="col-6">
                    <label>Email</label>
                    <b-form-input
                      class="mb-1"
                      required
                      v-model="emailEfectivoKushki"
                      @input="cambia()"
                      type="email"
                      id="input-nombreTitular"
                    />
                  </div>
                </div>
              </div>

              <div class="row align-items-center termsAndconds">
                <div>
                  <b-form-checkbox
                    v-model="aceptoTerminosCondiciones"
                    class="custom-control-info"
                  >
                    <label class="label-terms"
                      >Acepto los Términos y Condiciones</label
                    >
                  </b-form-checkbox>
                </div>
                <div>
                  <b-button
                    variant="link"
                    class="p-0 ml-1 mt-0 ver-mas-button"
                    @click="modalTermsShow = !modalTermsShow"
                    v-b-modal.modal-terms
                    >Ver mas</b-button
                  >
                </div>
                <label
                  v-if="terminos"
                  class="font-italic"
                  style="color: red !important"
                  >Debe aceptar los Terminos y Condiciones</label
                >
              </div>

              <div class="row align-items-center">
                <div class="col">
                  <div style="display: flex">
                    <div class="button-container">
                      <b-button
                        id="button-pagar"
                        variant="primary"
                        @click="validateEmptyFields()"
                        :disabled="activatePayButton(formaElegida)"
                      >
                        Pagar {{ dataLinkActivo.currencyString }} {{ this.total }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!linkVencido">
          <div class="col-12 text-center">
            <b-card body class="text-center card-shadow">
              <h4 class="font-weight-bolder">
                El link de pago todavía no esta activo.
              </h4>
              <p>Se habilitará el dia {{ fechaInicio }}</p>
            </b-card>
          </div>
        </div>
        <div v-else>
          <div class="col-12 text-center">
            <b-card body class="text-center card-shadow">
              <h4 class="font-weight-bolder">
                El link de pago ha expirado.
              </h4>
              <p>Se deshabilitó el dia {{ fechaValidacion }}</p>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-12 text-center">
        <b-card body class="text-center card-shadow">
          <h4 class="font-weight-bolder">
            El link de pago no existe.
          </h4>
        </b-card>
      </div>
    </div>

    <b-modal
      id="modal-terms"
      v-model="modalTermsShow"
      hide-header-close
      header-class="py-2"
      title="Términos y Condiciones"
      ok-only
      ok-title="Cerrar"
      centered
      size="xl"
      ref="modal-terms"
      scrollable
    >
      <div class="p-2">
        <span>
          {{ terminosCondiciones }}
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
// const stripe = Stripe(`${publicStripe}`)
import {
  BLink,
  BFormInput,
  BButton,
  BForm,
  BImg,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAlert,
  BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';
import draggable from 'vuedraggable';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import { mask } from 'vue-the-mask';
import {
  backendUrl,
  frontEndUrl,
  TOKEN_TRANSFER_KUSHKI,
  TOKEN_CASH_KUSHKI,
  TOKEN_DEBIT_KUSHKI,
} from '@/config';
// import { Stripe } from 'stripe';
import axios from 'axios';

import { Kushki } from '@kushki/js';
import { urlKushki } from '../../../config/index';
import { requestKushkiTokenCredit } from './kushki/index';
import validation from '../../../helpers/validate-fields';

import {
  siteUrl,
  ssl,
  enableLogs,
  celcomKushkiMerchantId,
  isProduction,
} from '../../../config/index';

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BCard,
    BAvatar,
    VuexyLogo,
    draggable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ToastificationContent,
    BAlert,
    vSelect,
    mask,
    BFormCheckbox,
  },

  data() {
    // this.pulishableKey = `${dPublicKey}`;
    return {
      paramsUrlRedirect:'',
      fechaInicio:null,
      resetData:false,
      showErrorDescuento:false,
      codigoEnabled:false,
      publicKushki: '',
      publicStripe: '',
      stripe: '',
      monedaString:'',
      priceString:'',
      kushki: '',
      hidePostalCode: true,
      token: null,
      charge: null,
      dataLinkActivo: {},
      fieldLinkActivo: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
      logoProducto: '@/assets/images/celcomLogo/logo.png',
      nombreProducto: '',
      descripcionProducto: '',
      list2: [],
      textoPromocional: '',
      nombreCliente: '',
      total: 0,
      moneda: {},
      redirectFlag:false,
      documentoTransfer: '',
      documentTypeTransfer: '',
      textMoneda: '',
      textMonedaArray: [],
      modalTermsShow: false,
      nombreTitular: '',
      numeroTarjeta: '',
      fechaExpiracion: null,
      fechaLanzamiento: null,
      linkVencido: false,
      linkEliminado: false,
      mesExp: '',
      anioExp: '',
      cvv: '',
      emailTitular: '',
      subtotal: null,
      cardComplete: false,
      inputsDynamicFormCompleted: false,
      dir: 'ltr',
      medioElegido: '',
      inputsMedioPagoVisible: true,
      formasPago: [],
      formaElegida: '',
      inputsFormaPagoVisible: false,
      modalTermsShow: false,
      mediosPago: [],
      metodoPago: [],
      pasarelasSelector: [],
      mediosPago2: [],
      periodicidad: '',
      terminos: null,
      formaCheck: null,
      linkCargado: false,
      validateDate: true,
      fechaVencimiento: null,
      fechaValidacion: '',
      pasarelaCheck: null,
      statesDynamicForm: [],
      textPeriodicidad: '',
      impuestos: 0,
      descuento: '',
      diasPrueba: '',
      clientSecret: '',
      cardObject: '',
      aceptoTerminosCondiciones: true,
      terminosCondiciones: '',
      nombreEfectivoKushki: '',
      apellidoEfectivoKushki: '',
      identificacionEfectivoKushki: '',
      emailEfectivoKushki: '',
      emailTransferenciaKushki: '',
      nombreCreditCard: '',
      apellidoCreditCard: '',
      telefonoCreditCard: '',
      emailCreditCard: '',
      direccionCreditCard: '',
      paisCreditCard: '',
      ciudadCreditCard: '',
      codigoPostalCreditCard: '',
      regionCreditCard: '',
      tipoDocumentoCard: '',
      documentoCard: '',
      emailDebitCard: '',
      codigoDesc: '',
    };
  },
  setup() {
    const optionMetodoPago = [
      { title: 'Tajeta de Credito', value: 1 },
      { title: 'Tajeta de Debito', value: 2 },
      { title: 'Efectivo', value: 3 },
      { title: 'Transferencia', value: 4 },
    ];
    const documentOptions = [
      { text: 'RUT', value: 'RUT' },
      { text: 'CC', value: 'CC' },
    
    ];
    const optionMediosPago = [
      { text: 'Tarjeta de Crédito', value: 1, pasarela: [1, 2] },
      { text: 'Tarjeta de Débito', value: 2, pasarela: [1] },
      { text: 'Efectivo', value: 3, pasarela: [1] },
      { text: 'Transferencia', value: 4, pasarela: [1] },
      { text: 'Entel', value: 5, pasarela: [3] },
      { text: 'Movistar', value: 6, pasarela: [3] },
    ];
    const optionPasarelas = [
      { text: 'Kushki', value: 1 },
      { text: 'Stripe', value: 2 },
      { text: 'Suscripciones Móviles', value: 3 },
    ];
    return {
      optionMediosPago,
      optionMetodoPago,
      optionPasarelas,
      documentOptions,
    };
  },
  directives: { mask },

  mounted() {
    this.guardarParametros();
    this.getLinkDePago();
  },
  methods: {
  copiarEmailInput(){
     for (let i = 0; i < this.list2.length; i++) {
        if (this.list2[i].name == "Email") {
          this.emailCreditCard = this.list2[i].model;
        }
      }
    },
    guardarParametros(){
      var a = '{"'+window.location.search.substr(1).replaceAll('=','":"').replaceAll('&','","')+'"}'
      a = JSON.parse(a)
      delete a.pass
      var b = Object.getOwnPropertyNames(a)
      var aux = ''
      for (let i = 0; i < b.length; i++) {
        aux += (i==0 ? '':'&') + b[i]+'='+a[b[i]]
      }
      this.paramsUrlRedirect=aux;
    },
    async aplicaDescuento() {
      try {
      if (this.codigoDesc != '' || this.codigoDesc != null) {
        this.$store.commit('appConfig/changeLoading', true);

        const respDescuento = await axios({
          method: 'post',
          url: `${backendUrl}/services/descuentos/verifyDiscount`,
          data: {
            code: this.codigoDesc,
            id_link: this.$router.history.current.params.id,
          },
        });
        try {
          if (respDescuento.data.code == 1) {
            this.$store.commit('appConfig/changeLoading', false);
              this.showErrorDescuento = true
            setTimeout(() => {
              this.codigoDesc = '';
              this.showErrorDescuento = false
            }, 1500);
            // this.showDescuentoErrorCode('danger', respDescuento.data.msg);
          } else {
            if (respDescuento.data.descuento.porcent) {
              this.descuento =
                this.total * (respDescuento.data.descuento.porcent / 100);
              this.total = this.total - this.descuento;
              this.$store.commit('appConfig/changeLoading', false);
              this.showDescuentoExitoso(
                'success',
                this.descuento,
                this.textMoneda
              );

            } else {
              this.descuento = respDescuento.data.descuento.amount;
              this.total = this.total - this.descuento;

              this.$store.commit('appConfig/changeLoading', false);
              this.showDescuentoExitoso(
                'success',
                this.descuento,
                this.textMoneda
              );
             }
            }
          } catch (error) {
            this.showDescuentoError('danger');
          }
        }
      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showDescuentoError('danger');
        return error
      }
    },
    elegirDocumento(tipoDoc) {
    },
    isRutCard(){
      return(this.tipoDocumentoCard === 'RUT' && !validation.isRut(this.documentoCard));
    },
    validateFieldsCashKushki() {
      return (
        this.formaElegida == 1 &&
        this.nombreCreditCard !== '' &&
        this.apellidoCreditCard !== '' &&
        this.documentoCard !== '' &&
        this.tipoDocumentoCard !== '' &&
        this.telefonoCreditCard !== '' &&
        this.direccionCreditCard !== '' &&
        this.paisCreditCard !== '' &&
        this.ciudadCreditCard !== '' &&
        this.regionCreditCard !== '' &&
        this.codigoPostalCreditCard !== '' &&
        this.tipoDocumentoCard !== '' &&
        this.emailCreditCard !== ''
      );
    },
    cambia() {

      if (
        this.nombreTitular != '' &&
        this.numeroTarjeta != '' &&
        this.cvv != '' &&
        this.cvv.length > 2 &&
        this.fechaExpiracion != ''
      ) {
        this.cardComplete = true;
      } else {
        this.cardComplete = false;
      }
    },
    verificaCambioPasarela(){
      if(this.medioElegido == 2 && this.formaElegida == 1){
        this.resetData = true
      } else if(this.medioElegido == 1 && this.formaElegida == 1) {
        this.resetData = true
      }else{
        this.resetData = false
      }
      if(this.resetData){
        this.nombreTitular = ""
        this.numeroTarjeta = ""
        this.fechaExpiracion = ""
        this.cvv =""
      }

    },
    

    showInputMedioPago() {
      this.inputsMedioPagoVisible = true;
    },
    hideInputMedioPago() {
      this.inputsMedioPagoVisible = false;
    },
    showInputFormaPago() {
      this.inputsFormaPagoVisible = true;
    },
    hideInputFormaPago() {
      this.inputsFormaPagoVisible = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showDescuentoErrorCode(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          text: 'Intente con otro',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showDescuentoError(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Hubo un error',
          text: 'Intente de nuevo',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showDescuentoExitoso(variant, descuento, moneda) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Descuento Realizado!',
          text: `${moneda} ${descuento} han sido descontados`,
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showPagoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Pago exitoso!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    cambiarMedio() {
    },

    showDateLinkInactive(variant, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'No es posible acceder al link',
          text: `Intente de nuevo el dia ${date}`,
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showPagoErrorToast(variant, mensaje) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${mensaje}`,
          text: 'Intente con otra tarjeta',
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showPagoError400(variant, mensaje) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${mensaje}`,
          text: 'Intente con otra pasarela o si el error continua contactese con un administrador',
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showErrorCreditCard(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          icon: 'XIcon',
          variant,
        },
      });
    },
    showTarjetaInvalida(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ocurrio un error con la tarjeta.',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showErrorTransfer(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ocurrio un error cuando se realizo la transferencia.',
          text: 'Intente nuevamente más',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showErrorCreditCard(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          icon: 'XIcon',
          variant,
        },
      });
    },

    async getLinkDePago() {
      this.$store.commit('appConfig/changeLoading', true);

      // const token = localStorage.getItem('token');

      var config = {
        method: 'post',
        url: `${backendUrl}/link-pago/linkPrevia`,
        data: {
          id: this.$router.history.current.params.id,
          pass: this.$router.history.current.query.pass,
        },
      };

      try {
        const resp = await axios(config);
        let publishableKey;

        for (let q = 0; q < resp.data.credentials.length; q++) {
          if (resp.data.credentials[q].id_gateway == 1) {
            this.publicKushki = resp.data.credentials[q].publicKey;
          } else {
            if(resp.data.credentials[q].id_gateway == 2){


            publishableKey = resp.data.credentials[q].publicKey;
            this.publicStripe = publishableKey
           

            this.stripe = Stripe(`${publishableKey}`);
            }
          }
        }

        let hoy = new Date();
        let desde = new Date(resp.data.link[0].date_from);
        let hasta =
          resp.data.link[0].date_to == null
            ? null
            : new Date(resp.data.link[0].date_to);
       
        if (desde > hoy) {
          this.fechaLanzamiento = desde;

            this.fechaInicio= new Date(this.fechaLanzamiento).getDate() +
            '/' +
            (new Date(this.fechaLanzamiento).getMonth() + 1) +
            '/' +
            new Date(this.fechaLanzamiento).getFullYear();
            
         
          this.validateDate = false;
          this.linkVencido = false;
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }
        if (hasta != null && hasta <= hoy) {
          this.fechaVencimiento = hasta;
          this.fechaValidacion =
            new Date(this.fechaVencimiento).getDate() +
            '/' +
            (new Date(this.fechaVencimiento).getMonth() + 1) +
            '/' +
            new Date(this.fechaVencimiento).getFullYear();

          this.validateDate = false;
          this.linkVencido = true;
          this.$store.commit('appConfig/changeLoading', false);

          return;
        }
        let linkActivo = await resp.data.link.filter(
          (e) => e.id == this.$route.params.id
        );

        this.linkData = linkActivo[0];

        this.dataLinkActivo = linkActivo[0];
        console.log('link:::', 
        this.dataLinkActivo)
        this.dataLinkActivo.pasarela = JSON.parse(this.dataLinkActivo.pasarela);
        this.dataLinkActivo.metodoPago = JSON.parse(
          this.dataLinkActivo.metodoPago
        );
    
        for (let k = 0; k < linkActivo[0].field.length; k++) {
          linkActivo[0].field[k].model = '';
        }
        this.list2 = linkActivo[0].field;
        for (let v = 0; v < this.list2.length; v++) {
          this.nombreCliente = this.list2[v].model;
        }
        this.nombreProducto = linkActivo[0].name;
        this.descripcionProducto = linkActivo[0].description;
        this.textoPromocional = linkActivo[0].textPromo;
        this.total = linkActivo[0].amount;
        this.subtotal = linkActivo[0].amount;
        this.moneda = parseInt(linkActivo[0].currency);
        this.monedaString = linkActivo[0].currencyString,
        this.priceString = linkActivo[0].priceString,
        this.periodicidad = linkActivo[0].periodicidad;
        this.textPeriodicidad =
          this.periodicidad == 1
            ? 'Dia'
            : this.periodicidad == 2
            ? 'Semanal'
            : this.periodicidad ==  3
            ? 'Quincenal'
            : this.periodicidad ==  4
            ? 'Mes'
            : this.periodicidad ==  5
            ? 'Trimestre'
            : this.periodicidad ==  6
            ? 'Semestre'
            : this.periodicidad ==  7
            ? 'Año'
            : null;
        this.terminosCondiciones = linkActivo[0].Terms_and_Conditions;
        this.metodoPago = this.dataLinkActivo.metodoPago;
        this.imagen = `${backendUrl}/link-pago/getImg/${linkActivo[0].url_imgage.replace(
          '/warehouse/',
          ''
        )}`;
        this.mediosPago = this.dataLinkActivo.pasarela; /* this.mediosPagoGen.map(function(x) {
          return x == 2 ? 'Stripe' : 1 ? 'Kushki' : null;
        }); */
        this.textMonedaArray = this.$store.state.appConfig.currency
        for (let m = 0; m < this.textMonedaArray.length; m++) {
          if (this.textMonedaArray[m].id == this.moneda) {
            this.textMoneda = this.textMonedaArray[m].code;
          }
        }
       
        for (let i = 0; i < this.list2.length; i++) {
          this.statesDynamicForm.push(null);
        }
        this.$store.commit('appConfig/changeLoading', false);
              console.log('pasarela::', this.medioElegido)

        this.linkCargado = true;
      } catch (error) {
        this.linkEliminado = true;
        this.validateDate = false;
        if (error) {
          this.errorCredentials('danger');
          this.$store.commit('appConfig/changeLoading', false);
        } else {
          this.$store.commit('appConfig/changeLoading', false);
          this.errorLinkToast('danger');
        }
      }
    },
    checkDynamicForm(name, model, index) {
      const Faltantes = [
        'Dirección',
        'Nombre de Empresa',
        'RUT de Empresa',
        'Giro de Empresa',
        'Dirección de Empresa',
      ];
      //this.statesDynamicForm[index]=false;
      if (model.length > 3) {
        if (name === 'Nombre' && !validation.isText(model)) {
          return true;
        } else if (name === 'Apellido' && !validation.isText(model)) {
          return true;
        } else if (name === 'Email' && !validation.isEmail(model)) {
          return true;
        } else if ((name === 'Teléfono' || name === 'TELEFONO' || name === 'Telefono') && !validation.isPhone(model)) {
          return true;
        } else if (name === 'RUT' && !validation.isRut(model)) {
          return true;
        } else if (name === 'Email de Empresa' && !validation.isEmail(model)) {
          return true;
        } else if (name === 'RUT de Empresa' && !validation.isRut(model)) {
          return true;
        }
        //this.statesDynamicForm[index]=true;
        return false;
      } else {
        return true;
      }
    },
    EmptyFields() {
      for (let i = 0; i < this.list2.length; i++) {
        this.statesDynamicForm[i] = false;
        this.statesDynamicForm[i] = !this.checkDynamicForm(
          this.list2[i].name,
          this.list2[i].model,
          i
        );
      }
      this.statesDynamicForm = [...this.statesDynamicForm];
      this.terminos = !this.aceptoTerminosCondiciones;
      this.medioElegido != null && this.medioElegido != ''
        ? (this.pasarelaCheck = false)
        : (this.pasarelaCheck = true);
      this.formaElegida != null && this.formaElegida != ''
        ? (this.formaCheck = false)
        : (this.formaCheck = true);
      return (
        this.statesDynamicForm.includes(false) ||
        this.pasarelaCheck ||
        this.terminos ||
        this.formaCheck
      );
    },

    async validateEmptyFields() {
      this.textMoneda = this.dataLinkActivo.currencyString;
      this.$store.commit('appConfig/changeLoading', true);

      if (this.EmptyFields()) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorFields('danger');

        return;
      }

      if (this.formaElegida == 2) {
        const redirectUrl = await this.debitKushki();
        if(this.redirectFlag)
          this.redirect(redirectUrl);
        return;
      }

      if (this.formaElegida == 3) {
        var regexString = /^[a-zA-Z ]+$/;
        var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        //validar nombre
        if (
          !regexString.test(this.nombreEfectivoKushki) ||
          !regexString.test(this.apellidoEfectivoKushki)
        ) {
          this.showErrorNombreError('danger');
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }
        if (!regex.test(this.emailEfectivoKushki)) {
          this.showErrorMailToast('danger');
          this.$store.commit('appConfig/changeLoading', false);
          return;
        } 
        const pdfUrl = await this.cashKushki();
        if(this.redirectFlag)
          this.redirect(pdfUrl);
        return;
      }

      if (this.formaElegida == 4) {
        var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regex.test(this.emailTransferenciaKushki)) {
          this.showErrorMailToast('danger');
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }

        await this.transferTokenKushki();
      } else {
        if (this.cardComplete) {
          this.$store.commit('appConfig/changeLoading', true);
        } else {
          this.showErrorToast('danger');
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }
        

        //verifica que pasarela es
        if (this.medioElegido == 2) {
          if(this.cambia()){
            console.log('ha cambiado')
          }
          var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          var regexString = /^[a-zA-Z ]+$/;
          //validar mail
          if (!regex.test(this.emailCreditCard)) {
            this.showErrorMailToast('danger');
            this.$store.commit('appConfig/changeLoading', false);

            return;
          }
          //validar nombre y apellido
          if (!regexString.test(this.nombreTitular)) {

            this.$store.commit('appConfig/changeLoading', false);
            this.showErrorNombreError('danger');
            return;
          }
          this.anioExp = this.fechaExpiracion.split('/')[1];
          this.mesExp = this.fechaExpiracion.split('/')[0];

          this.inputsDynamicFormCompleted = true;
          for (let i = 0; i < this.list2.length; i++) {
            if (this.list2[i].model == '') {
              break;
            }
          }

          for (let g = 0; g < this.list2.length; g++) {
            const element = {
              name: this.list2[g].name ? this.list2[g].name : '',
              value: this.list2[g].model,
            };
            this.fieldLinkActivo.push(element);
          }

          for (let k = 0; k < this.fieldLinkActivo.length; k++) {
            if (this.fieldLinkActivo[k].name == 'Email') {
              this.emailTitular = this.fieldLinkActivo[k].value;
              break;
            }
          }

          //aqui genera el token de pago

          let card = {
            number: this.numeroTarjeta,
            cvc: this.cvv,
            exp_month: this.mesExp,
            exp_year: this.anioExp,
          };

          if (this.dataLinkActivo.type == 1) {
            this.payStripe(card);
          } else {
            this.suscribeStripe(card);
          }
        } else {
          var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          var regexString = /^[a-zA-Z ]+$/;
          //validar mail
          if (!regex.test(this.emailCreditCard)) {
            this.showErrorMailToast('danger');
            this.$store.commit('appConfig/changeLoading', false);

            return;
          }
          //validar nombre y apellido
          if (
            !regexString.test(this.nombreTitular) ||
            !regexString.test(
              this.nombreCreditCard
            )
          ) {
            this.$store.commit('appConfig/changeLoading', false);
            this.showErrorNombreError('danger');
            return;
          }
          //validar pais direccion region y ciudad
          if (
            
            !regexString.test(this.paisCreditCard) ||
            !regexString.test(this.regionCreditCard) ||
            !regexString.test(this.ciudadCreditCard)
          ) {
            this.$store.commit('appConfig/changeLoading', false);
            this.showDatosGeograficosError('danger');
            return;
          }

          if(this.isRutCard()){
            this.showGenericToast({
              title: 'RUT invalido.',
              text: 'Ejemplo 12345678-0',
              icon: 'AlertCircleIcon',
              variant: 'warning'
            });
            this.$store.commit('appConfig/changeLoading', false);
            return;
          }

          //pago con kushki
          if (this.dataLinkActivo.type == 1) {
            this.anioExp = this.fechaExpiracion.split('/')[1];
            this.mesExp = this.fechaExpiracion.split('/')[0];

            var data = {
              totalAmount: this.total,
              currency: this.textMoneda,
              card: {
                name: this.nombreTitular,
                number: this.numeroTarjeta,
                expiryMonth: this.mesExp,
                expiryYear: this.anioExp,
                cvv: this.cvv,
              },
            };
            var config = {
              method: 'post',
              url: `${urlKushki}/card/v1/tokens/`,
              headers: {
                'Public-Merchant-Id': this.publicKushki,
              },
              data,
            };

            try {
              const resp = await axios(config);

              this.token = resp.data.token;
              let kushkiToken = this.token;

              this.payKushki(kushkiToken);
            } catch (error) {
            
              this.nombreTitular = '';
              this.numeroTarjeta = '';
              this.fechaExpiracion = '';
              this.cvv = '';
              this.$store.commit('appConfig/changeLoading', false);
              this.showPagoError400('danger', 'Tarjeta o pasarela invalida');
            }
          } else {
            this.anioExp = this.fechaExpiracion.split('/')[1];
            this.mesExp = this.fechaExpiracion.split('/')[0];

            var data = {
              card: {
                name: this.nombreTitular,
                number: this.numeroTarjeta,
                expiryMonth: this.mesExp,
                expiryYear: this.anioExp,
                cvv: this.cvv,
              },
              currency: this.textMoneda,
            };

            var config = {
              method: 'post',
              url: `${urlKushki}/subscriptions/v1/card/tokens`,
              headers: {
                'Public-Merchant-Id': this.publicKushki,
              },
              data,
            };

            try {
              const resp = await axios(config);
              this.token = resp.data.token;
              let kushkiToken = this.token;
              this.suscribeKushki(kushkiToken);
            } catch (error) {
              this.nombreTitular = '';
              this.numeroTarjeta = '';
              this.fechaExpiracion = '';
              this.cvv = '';
              this.showPagoError400('danger', 'Tarjeta o pasarela invalida');
              this.$store.commit('appConfig/changeLoading', false);
            }
          }
        }
      }
    },

    validateFieldsCashKushki() {
      return (
        this.formaElegida == 3 &&
        this.nombreEfectivoKushki !== '' &&
        this.apellidoEfectivoKushki !== '' &&
        this.identificacionEfectivoKushki !== '' &&
        this.emailEfectivoKushki !== ''
      );
    },

    async getCashTokenKushki() {
      //this.loading = true;
      this.$store.commit('appConfig/changeLoading', true);

      const data = {
        name: this.nombreEfectivoKushki,
        lastName: this.apellidoEfectivoKushki,
        identification: this.identificacionEfectivoKushki,
        email: this.emailEfectivoKushki,
        totalAmount: this.total,
        currency: this.textMoneda,
        description: this.descripcionProducto,
        documentType: 'RUT',
      };

      const config = {
        method: 'post',
        url: TOKEN_CASH_KUSHKI,
        headers: {
          'Public-Merchant-Id': this.publicKushki,
          'Content-Type': 'application/json',
        },
        data,
      };

      try {
        const resp = await axios(config);
        this.redirectFlag=true
        return resp.data.token;
      } catch (error) {
        this.showPagoError400('danger', 'Pasarela invalida');
        this.redirectFlag=false;
        this.$store.commit('appConfig/changeLoading', false);

      }
    },

    async cashKushki() {
      try {
        const token = await this.getCashTokenKushki();
        const data = {
          token,
          amount: this.total,
          id_project: this.dataLinkActivo.id_project,
          type: this.dataLinkActivo.type,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency: parseInt(this.dataLinkActivo.currency),
          email_user_app: this.emailEfectivoKushki,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.dataLinkActivo.field,
          token_pay: token,
          used: 0,
        };

        const config = {
          method: 'post',
          url: `${backendUrl}/services/kushkiCashPayment/`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        const resp = await axios(config);

        return resp.data.pdfUrl;
      } catch (err) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorTransfer('danger');
      }
    },

    async debitTokenKushki() {
      const data = {
        totalAmount: this.total,
        returnUrl: `${frontEndUrl}payment-success`,
        currency: this.textMoneda,
      };

      const config = {
        method: 'post',
        url: TOKEN_DEBIT_KUSHKI,
        headers: {
          'Public-Merchant-Id': this.publicKushki,
          'Content-Type': 'application/json',
        },
        data,
      };

      try {
        const resp = await axios(config);

        this.redirectFlag=true;
        return resp.data.token;
      } catch (error) {
        this.redirectFlag=false;
        this.$store.commit('appConfig/changeLoading', false);
        this.showPagoError400('danger', 'Esta pasarela no puede usarse');
        return error;
      }
    },

    async debitKushki() {
      const token = await this.debitTokenKushki();

      const data = {
        token,
        amount: this.total,
        id_project: this.dataLinkActivo.id_project,
        type: this.dataLinkActivo.type,
        date: new Date()
          .toJSON()
          .slice(0, 19)
          .replace('T', ' '),
        id_credential: this.medioElegido,
        id_state: 1,
        id_currency:
          this.dataLinkActivo.currency == '1'
            ? 1
            : this.dataLinkActivo.currency == '2'
            ? 2
            : 3,
        email_user_app: this.emailDebitCard,
        id_link: this.dataLinkActivo.id,
        transaction_field: this.dataLinkActivo.field,
        token_pay: token,
        used: 0,
      };

      const config = {
        method: 'post',
        url: `${backendUrl}/services/kushkiDebit/`,
        headers: { 'Content-Type': 'application/json' },
        data,
      };

      try {
        const resp = await axios(config);

        return resp.data.redirectUrl;
      } catch (error) {
        return error;

      }
    },

    async transferTokenKushki() {
      let currencyFilter = this.$store.state.appConfig.currency.filter(
        (x) => x.id == parseInt(this.dataLinkActivo.currency)
      );
    
      // let currencyString = currencyFilter[0].code;
      let currencyString = this.dataLinkActivo.currencyString;
      var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!regex.test(this.emailTransferenciaKushki)) {
        this.showErrorMailToast('danger');
        this.$store.commit('appConfig/changeLoading', false);
        return;
      }

      let config = {
        method: 'post',
        url: TOKEN_TRANSFER_KUSHKI,
        headers: {
          'Content-Type': 'application/json',
          'Public-Merchant-Id': this.publicKushki,
        },
        data: {
          amount: {
            subtotalIva: 0,
            subtotalIva0: this.total,
            iva: 0,
          },
          callbackUrl: `${frontEndUrl}payment-success`,
          userType: '0',
          documentType: this.documentTypeTransfer,
          documentNumber: this.documentoTransfer,
          paymentDescription: this.descripcionProducto,
          email: this.emailTransferenciaKushki,
          currency: currencyString,
        },
      };


      try {
        let resp = await axios(config);
        this.token = resp.data.token;
        await this.transferKushki(this.token);
      } catch (error) {

        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorTransfer('danger');
      }
    },

    async transferKushki(token) {
      const config = {
        method: 'post',
        url: `${backendUrl}/services/kushkiTransfer/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token,
          id_project: this.dataLinkActivo.id_project,
          description: this.descripcionProducto,
          amount: this.total,
          type: this.dataLinkActivo.type,
          product: this.nombreProducto,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency:
            this.dataLinkActivo.currency,
          currencyString: this.dataLinkActivo.currencyString,
          // currencyString: this.textMoneda,
          email_user_app: this.emailTransferenciaKushki,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.dataLinkActivo.field,
          used: 0,
          baja: 0,
          periodicidad: this.dataLinkActivo.periodicidad,
          diasPrueba: this.dataLinkActivo.diasPrueba,
        },
      };

      try {
        const response = await axios(config);

        if (response.data.code == 2) {
          this.redirect(response.data.redirectUrl);
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
        } else throw new Error('err en la tarjeta');
      } catch (err) {
        
        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorTransfer('danger');
      }
    },

    redirect(red) {
      // this.$store.commit("appConfig/savePayment", true);
      window.location.replace(red);
    },

    async payKushki(kushkiToken) {
      let currencyFilter = this.$store.state.appConfig.currency.filter(
        (x) => x.id == parseInt(this.dataLinkActivo.currency)
      );
      // let currencyString = currencyFilter[0].code;
      let currencyString = this.dataLinkActivo.currencyString;

      var config2 = {
        method: 'post',
        url: `${backendUrl}/services/kushkiCreditPayment/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id_project: this.dataLinkActivo.id_project,
          description: this.descripcionProducto,
          amount: this.total,
          lastNameCard: this.apellidoCreditCard,
          paisCad: this.paisCreditCard,
          ciudadCard: this.ciudadCreditCard,
          regionCard: this.regionCreditCard,
          codigoPostalCard: this.codigoPostalCreditCard,
          clienteCard: this.nombreCreditCard,
          emailCard: this.emailCreditCard,
          tipoDocCard: this.tipoDocumentoCard,
          numeroDocCard: this.documentoCard,
          domicilioCard: this.direccionCreditCard,
          telefonoCard: this.telefonoCreditCard,
          type: this.dataLinkActivo.type,
          product: this.nombreProducto,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency: parseInt(this.dataLinkActivo.currency),
          currencyString: currencyString,
          email_user_app: this.emailCreditCard,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.dataLinkActivo.field,
          used: 0,
          baja: 0,
          periodicidad: this.dataLinkActivo.periodicidad,
          diasPrueba: this.dataLinkActivo.diasPrueba,
          token: kushkiToken,
        } /* ) */,
      };


      try {
        const res2 = await axios(config2);
        
        if (res2.data.code == 2) {

          let idtrr = res2.data.id_transaction;

          let resApi = {
            idProject: this.dataLinkActivo.id_project,
            type: 'PAGO-ÚNICO',
            body: {
              idLink: this.dataLinkActivo.id,
              idTransaction: idtrr,
              clientParams: window.location.href.substr(window.location.href.indexOf('?')),
              pasarela: 'Kushki',
              ...res2.data,
            },
          };

          fetch(`${backendUrl}/services/buy/verify`, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(resApi),
          })
            .then((response) => response.json())
            .then((sessionss) => {
              this.$store.state.appConfig.pay = true;
              this.$router.push('/pages/miscellaneous/PaymentSuccess');
              this.$store.commit('appConfig/changeLoading', false);
            });

          if (this.dataLinkActivo.callback) {
            let varp =
              (this.dataLinkActivo.callback.indexOf('?') != -1 ? '&' : '?') +
              'idLink=' + this.dataLinkActivo.id+ '&idtr='+idtrr
              ;
            var param='';
            if(this.paramsUrlRedirect.length){
              param='&'+this.paramsUrlRedirect
            }
            window.localStorage.setItem(
              'cb',
              this.dataLinkActivo.callback + (varp?varp:'')+(param?param:'')
            );
          } else {
            window.localStorage.removeItem('cb');
          }
          this.$store.commit('appConfig/savePayment', true);
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
          this.$router.push('/pages/miscellaneous/PaymentSuccess');
        } else {
          this.numeroTarjeta = '';
          this.nombreTitular = '';
          this.fechaExpiracion = '';
          this.cvv = '';

          this.$store.commit('appConfig/changeLoading', false);
          this.showErrorCreditCard('danger', res2.data.message);
        }
      } catch (error) {
       
        this.$store.commit('appConfig/changeLoading', false);
        this.showTarjetaInvalida('danger');
      }
    },

    async suscribeKushki(kushkiToken) {
      let currencyFilter = this.$store.state.appConfig.currency.filter(
        (x) => x.id == parseInt(this.dataLinkActivo.currency)
      );
      // let currencyString = currencyFilter[0].code;
      let currencyString = this.dataLinkActivo.currencyString;
      var config2 = {
        method: 'post',
        url: `${backendUrl}/services/kushkiCreditSuscription/`,
        headers: {
          'Content-Type': 'application/json',
          // 'Public-Merchant-Id': this.publicKushki,
        },
        data: /* JSON.stringify( */ {
          id_project: this.dataLinkActivo.id_project,
          description: this.descripcionProducto,
          amount: this.total,
          type: this.dataLinkActivo.type,
          product: this.nombreProducto,
          lastName: this.apellidoCreditCard,
          pais: this.paisCreditCard,
          ciudad: this.ciudadCreditCard,
          region: this.regionCreditCard,
          codigoPostal: this.codigoPostalCreditCard,
          cliente: this.nombreCreditCard,
          email: this.emailCreditCard,
          tipoDoc: this.tipoDocumentoCard,
          numeroDoc: this.documentoCard,
          domicilio: this.direccionCreditCard,
          telefono: this.telefonoCreditCard,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency: parseInt(this.dataLinkActivo.currency),
          currencyString: currencyString,
          email_user_app: this.emailCreditCard,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.dataLinkActivo.field,
          used: 0,
          baja: 0,
          periodicidad: this.dataLinkActivo.periodicidad,
          diasPrueba: this.dataLinkActivo.diasPrueba,
          token: kushkiToken,
        } /* ) */,
      };
      try {


        const res2 = await axios(config2);
        if (res2.data.code == 2) {
          let idtrr = res2.data.id_transaction
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          let resApi = {
            idProject: this.dataLinkActivo.id_project,
            type: 'SUSCRIPCIÓN',
            body: {
              idLink: this.dataLinkActivo.id,
              idTransaction: idtrr,
              clientParams: window.location.href.substr(window.location.href.indexOf('?')),
              pasarela: 'Kushki',
              ...res2.data,
            },
          };
          fetch(`${backendUrl}/services/buy/verify`, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(resApi),
          })
            .then((response) => response.json())
            .then((sessionss) => {
              this.$store.state.appConfig.pay = true;
              this.$router.push('/pages/miscellaneous/PaymentSuccess');
              this.$store.commit('appConfig/changeLoading', false);
            });
          if (this.dataLinkActivo.callback) {
            let varp =
              (this.dataLinkActivo.callback.indexOf('?') != -1 ? '&' : '?') +
              'idLink=' + this.dataLinkActivo.id+ '&idtr='+idtrr;
            var param='';
            if(this.paramsUrlRedirect.length){
              param='&'+this.paramsUrlRedirect
            }
            window.localStorage.setItem(
              'cb',
              this.dataLinkActivo.callback + (varp?varp:'')+(param?param:'')
            );
          } else {
            window.localStorage.removeItem('cb');
          }
          this.$store.commit('appConfig/savePayment', true);
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
          this.$router.push('/pages/miscellaneous/PaymentSuccess');
        } else {
          this.$store.commit('appConfig/changeLoading', false);

          this.showErrorCreditCard('danger', res2.data.message);
        }
      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorCreditCard('danger', res2.data.message);

      }
    },
    async payStripe(card) {
      fetch(`${backendUrl}/services/buy/checkout`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          card: card,
          id_project: this.dataLinkActivo.id_project,
          description: this.descripcionProducto,
          amount: this.total,
          type: this.dataLinkActivo.type,
          product: this.nombreProducto,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency: parseInt(this.dataLinkActivo.currency),
          email_user_app: this.emailCreditCard,
          currencyString: this.monedaString,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.fieldLinkActivo,
          permanent: this.dataLinkActivo.permanent,
          used: 0,
          baja: 0,
          customer: this.nombreTitular,
          priceString: this.priceString
        }),
      })
        .then((response) => response.json())
        .then((session) => {
          if (session.code == 1) {
            //this.loading = false;
            this.$store.commit('appConfig/changeLoading', false);

            if(session.message.includes('Invalid API Key provided'))
              this.showPagoError400('danger', 'Esta pasarela no puede usarse');
            else
              this.showPagoErrorToast('danger', session.message == "incorrect_number" ? 'Numero de tarjeta incorrecto' : 'card_declined' ? 'Fondos insuficientes' : 'Hubo un error al realizar el pago, intente nuevamente');
            this.nombreTitular = '';
            this.numeroTarjeta = '';
            this.cvv = '';
            this.fechaExpiracion = '';
            // this.nombre
          }
          this.stripe.confirmCardPayment(session.client_secret, {
              payment_method: session.payment_method.id
            })
            .then((result) => {
              if (result.paymentIntent.status == 'succeeded') {
                if (this.dataLinkActivo.callback) {
                  let varp =
                    (this.dataLinkActivo.callback.indexOf('?') != -1
                      ? '&'
                      : '?') +
                    'idtr=' + session.id_transaction + '&idLink='+this.dataLinkActivo.id;
                  var param='';
                  if(this.paramsUrlRedirect.length){
                    param='&'+this.paramsUrlRedirect
                  }
                  window.localStorage.setItem(
                    'cb',
                    this.dataLinkActivo.callback + (varp?varp:'')+(param?param:'')
                  );
                } else {
                  window.localStorage.removeItem('cb');
                }

                let resApi = {
                  idProject: this.dataLinkActivo.id_project,
                  type: 'PAGO-ÚNICO',
                  body: {
                    idLink: this.dataLinkActivo.id,
                    idTransaction: session.id_transaction,
                    clientParams: window.location.href.substr(window.location.href.indexOf('?')),
                    pasarela: 'Stripe',
                    ...result,
                  },
                };

                fetch(`${backendUrl}/services/buy/verify`, {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(resApi),
                })
                  .then((response) => response.json())
                  .then((sessionss) => {
                    this.$store.state.appConfig.pay = true;
                    this.$router.push('/pages/miscellaneous/PaymentSuccess');
                    this.$store.commit('appConfig/changeLoading', false);
                }); 
              }
            })
            .catch((err) =>  {
              console.log('errorrrrrr', err)
              // Esta linea hace que la pagina deje de cargar
              this.$store.commit('appConfig/changeLoading', false);
              //las siguentes lineas limpian los datos de la tarjeta
              this.nombreTitular = '';
              this.numeroTarjeta = '';
              this.cvv = '';
              this.fechaExpiracion = '';
              this.showPagoErrorToast('danger', 'Ocurrio un problema con el pago');
            });
        });
    },
    async suscribeStripe(card) {
      fetch(`${backendUrl}/services/suscribe/subscription`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          card: card,
          customer: this.nombreTitular,
          id_project: this.dataLinkActivo.id_project,
          description: this.descripcionProducto,
          amount: this.total,
          type: this.dataLinkActivo.type,
          product: this.nombreProducto,
          date: new Date()
            .toJSON()
            .slice(0, 19)
            .replace('T', ' '),
          id_credential: this.medioElegido,
          id_state: 1,
          id_currency: parseInt(this.dataLinkActivo.currency),
          email_user_app: this.emailCreditCard,
          id_link: this.dataLinkActivo.id,
          transaction_field: this.fieldLinkActivo,
          used: 0,
          baja: 0,
          periodicidad: this.dataLinkActivo.periodicidad,
          diasPrueba: this.dataLinkActivo.diasPrueba,
          customer: this.nombreTitular,
          currencyString: this.monedaString,
          priceString: this.priceString
        }),
      })
        .then((response) => response.json())
        .then((session) => {
          if (session.code == 1) {
           
            this.$store.commit('appConfig/changeLoading', false);
            if(session.message.includes('Invalid API Key provided'))
              this.showPagoError400('danger', 'Esta pasarela no puede usarse');
            else
            this.showPagoErrorToast('danger', session.message == "incorrect_number" ? 'Numero de tarjeta incorrecto' : 'card_declined' ? 'Fondos insuficientes' : 'Hubo un error al realizar el pago, intente nuevamente');            this.nombreTitular = '';
            this.numeroTarjeta = '';
            this.cvv = '';
            this.fechaExpiracion = '';
          }else if(session.code == 2){
            
          if (this.dataLinkActivo.callback) {
            let varp =
              (this.dataLinkActivo.callback.indexOf('?') != -1
                ? '&'
                : '?') +
              'idLink=' + this.dataLinkActivo.id+ '&idtr=' + session.id_transaction
            var param='';
            if(this.paramsUrlRedirect.length){
              param='&'+this.paramsUrlRedirect
            }
            window.localStorage.setItem(
              'cb',
              this.dataLinkActivo.callback + (varp ? varp:'')+(param ? param:'')
            );
          } else {
            window.localStorage.removeItem('cb');
          }

          let resApi = {
            idProject: this.dataLinkActivo.id_project,
            type: 'SUSCRIPCIÓN',
            body: {
              idLink: this.dataLinkActivo.id,
              idTransaction: session.id_transaction,
              clientParams: window.location.href.substr(window.location.href.indexOf('?')),
              pasarela: 'Stripe'
            },
          };

          fetch(`${backendUrl}/services/buy/verify`, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(resApi),
          })
            .then((response) => response.json())
            .then(async (sessionss) => {
              this.$store.commit('appConfig/savePayment', true);
              const token = localStorage.getItem('token');
              const response = await axios({
                method: 'get',
                url: `${backendUrl}/operaciones/suscripcion/`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              const suscripcionesFiltradas = response.data.suscriptions.filter(
                (e) =>
                  e.id_project == this.$store.state.appConfig.projecto.id
              );

              this.$store.commit(
                'appConfig/saveSuscripciones',
                suscripcionesFiltradas
              );

              this.$router.push('/pages/miscellaneous/PaymentSuccess');
              this.$store.commit('appConfig/changeLoading', false);
            });
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },

    showGenericToast({variant, title, text, icon}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant,
        },
      });
    },

    showErrorMailToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'El campo de mail no es correcto',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showDatosGeograficosError(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title:
            'Los campos de domicilio, pais, region y ciudad no pueden contener numeros',
          icon: 'CheckIcon',
          variant,
        },
      });
    },

    showErrorNombreError(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡El nombre y/o apellido no puede tener numeros!',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showErrorFields(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error en los campos',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    errorCredentials(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Este link de pago no puede ser pagado',
          text: 'Comuniquese con el administrador para solicitar un nuevo link',
          icon: 'XIcon',
          variant,
        },
      });
    },
    errorLinkToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Este link de pago no existe o ya ha sido utilizado',
          icon: 'XIcon',
          variant,
        },
      });
    },
    pasarelasSel() {
      var me = this;
      this.medioElegido = '';
      var result = [];
      if (me.formaElegida != '') {
        result = me.optionPasarelas.filter((pasa) => {
          var bool = false;
          me.optionMediosPago.map((medios) => {
            if (
              this.mediosPago.includes(pasa.value) &&
              medios.pasarela.indexOf(pasa.value) > -1 &&
              me.formaElegida == medios.value
            ) {
              bool = true;
            }
          });
          if (bool) return pasa;
        });
      }
      this.pasarelasSelector = Object.assign([], result);
      if(result.length == 1){
        this.medioElegido = result[0].value
      }

    },

    activatebuttonByMeansOfPayment(number) {
      return (
        this.medioElegido == 1 &&
        this.formaElegida == number &&
        this.aceptoTerminosCondiciones
      );
    },

    activatePayButton(formaSeleccionada) {
      const activate = {
        1: this.cardComplete,
        2: true,
        3: this.validateFieldsCashKushki(),
        4: this.medioElegido == 1,
      };

      return !(activate[formaSeleccionada] && this.aceptoTerminosCondiciones);
    },

    formatDate(dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      return dt + '/' + month + '/' + year;
    },
  },
  computed: {
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg');
      //   return this.downImg;
      // }
      // return this.downImg;
    },
    formaSelector() {
      var me = this;
      var result = me.optionMediosPago.filter((medio) => {
        if (me.metodoPago.indexOf(medio.value) != -1) return medio;
      });
      if(result.length == 1){
        this.formaElegida = result[0].value
        this.pasarelasSel()
      }
      console.log('result:::', result)
      return result;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.clearfix {
  clear: both;
  float: none;
}
.mb-1 form-control {
  padding: 20px;
  border: 1px solid #ccc;
}

#card-monto {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.textPromocional {
  text-align: left;
}
.card-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

#descripcion {
  text-align: left;
  display: block;
  font-size: 13px;
  line-height: 21px;
}

#button-pagar {
  margin-top: 24px;
  width: 100%;
}

.bold {
  font-weight: bold;
}

#input-nombreTitular {
  text-transform: uppercase;
}

.size-span-selecciona-medio {
  font-size: 12px;
}

.termsAndconds {
  margin-left: 0%;
  margin-top: 8px;
}

.avatarClass img {
  object-fit: contain !important;
}
.total-amount{
  font-size: 18px;
  font-weight: 600;
}
.ver-mas-button {
  margin-bottom: 4px;
  font-weight: bolder;
  color: $info;
}

.modal .modal-content {
  height: 540px;
}
.credit-card-data{
  background: rgba(0, 180, 200, .3);
  padding: 1%;
  border-radius: 20px;
}

.label-terms {
  font-weight: bolder;
  font-size: 14px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

#button-validar {
  width: 100%;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
@media (min-width:320px){
  .text-card-detail{
    width: 100%;
    text-align: center;
  }
  .button-container{
    width: 100%;
    justify-content: center;
  }
  #descripcion{
    width: 100%;
    text-align: center;
  }
}
.error-codigoDescuento{
  color:#f00;
  font-weight: 600;
  margin-top: 20px;
  font-size: 14px;

}
@media (min-width:993px){
  .text-card-detail{
    width: 100%;
    text-align: left;
  }
  #descripcion{
    text-align: left;
  }
   .button-container{
     width: 100%;
     display: flex;
    justify-content: flex-end;

  }
  #button-pagar{

    width: 50%;
   
  }
}
</style>
